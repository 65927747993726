.sidebar {
  display: flex;
  position: relative;
  top: 163px;
  flex-direction: column;
  align-items: center;
  justify-content: first baseline;
  height: calc(100vh - 166px);
  background-color: var(--main-blue);
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  transition: transform 0.3s ease-in-out;
  border-right: 3px solid var(--outline-color);
}

.divider-root {
  width: 100%;
  height: 2px;
  background-color: var(--outline-color);
  margin: 0px;
  padding: 0px;
}

.sidebar-list {
  display: flex !important;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center !important;
  align-self: start;
  position: relative;
  top: 0px;
  margin: 0 !important;
  padding: 0 !important;
  width: 100%;
}

.sidebar-options {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center;
  min-width: 100%;
  height: 100%;
  margin: 0 !important;
  padding: 10px 0 !important;
  font-family: var(--default-font);
  font-weight: var(--default-font-weight) !important;
}

.sidebar-options:hover {
  background-color: var(--argus-blue-dark);
  cursor: pointer;
}

#sidebar-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-family: var(--default-font) !important;
  gap: 25px;
  margin: 0 !important;
  padding: 10px !important;
  font-size: 16px;
  font-weight: var(--default-font-weight) !important;
  min-width: 100% !important;
  color: var(--argus-white) !important;
  pointer-events: all;
}

#sidebar-btn:hover,
#sidebar-btn:focus,
#sidebar-btn:active {
  background-color: transparent;
  cursor: pointer;
}

.menu-icon {
  color: var(--argus-white) !important;
  fill: var(--argus-white) !important;
  margin: 0 0 0 5px !important;
  padding: 0 !important;
}

#logout-btn {
  margin: 35px 0 auto 0;
  color: var(--btn-white-text) !important;
  font-family: var(--default-font);
  font-size: 15px;
  font-weight: var(--default-font-weight);
  background: var(--btn-red);
  width: 120px;
}

#logout-btn:hover {
  background: var(--btn-red-hover);
}

.sidebar-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  gap: 10px;
  margin-bottom: 10px;
}

#contact-phone,
#contact-email {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start !important;
  gap: 18px;
  width: 255px;
  padding: 0 !important;
}

#email-icon-btn,
#phone-icon-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 255px;
  gap: 18px;
  padding: 0;
  margin: 0;
  border-radius: 5px;
  font-family: var(--default-font) !important;
  font-size: 16px;
  font-weight: var(--default-font-weight);
  color: var(--argus-white);
}

.contact-text {
  font-family: var(--default-font) !important;
  font-size: 20px;
  font-weight: var(--default-font-weight);
  color: var(--argus-white);
  padding: 10px 0 !important;
  margin: 0 !important;
}

#phone-icon,
#email-icon {
  color: var(--argus-white);
  fill: var(--argus-white);
  height: 28px;
  width: 28px;
  filter: drop-shadow(0px 2px 1px rgba(36, 36, 36, 0.35));
}

#social-media {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 255px;
  padding: 0 !important;
  margin: 0 !important;
}

#social-media-icons-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 80px;
}

#social-media-p {
  font-family: var(--default-font) !important;
  font-size: 20px;
  font-weight: var(--default-font-weight);
  color: rgb(228, 228, 228);
  padding: 10px 0 !important;
  margin: 0 0 0 2px !important;
}

#linkedin-icon-btn,
#facebook-icon-btn {
  padding: 0 !important;
  height: 30px;
  width: 30px;
  background-color: white !important;
  border-radius: 4px;
}

#linkedin-icon {
  color: #0072b1;
  fill: #0072b1;
  margin: 0 !important;
  padding: 0 !important;
  height: 30px;
  width: 30px;
}

#facebook-icon {
  color: #4267b2;
  fill: #4267b2;
  height: 30px;
  width: 30px;
  margin: 0 !important;
  padding: 0 !important;
}

#mission-statement-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 255px;
  text-align: center;
  max-height: fit-content;
}

#mission-statment {
  font-family: var(--default-font) !important;
  font-size: 20px;
  font-weight: var(--default-font-weight);
  color: rgb(230, 230, 230);
  padding: 10px 0 !important;
  margin: 0 !important;
}

#main-logo-icon {
  height: 140px !important;
  width: 150px !important;
}

@media screen and (width < 1450px) {
  .sidebar {
    width: 265px;
  }

  #sidebar-btn {
    font-size: 14px;
  }

  #logout-btn {
    width: 90px;
    padding: 0;
    margin-top: 15px;
    margin-bottom: 8px;
    font-size: 13px;
    min-height: 28px !important;
  }

  #email-icon-btn,
  #phone-icon-btn {
    font-size: 14px;
  }

  #phone-icon,
  #email-icon {
    height: 22px;
    width: 22px;
  }

  .contact-text {
    font-size: 16px;
    padding: 8px 0 !important;
  }

  #social-media-icons-container {
    width: 70px;
    gap: 15px;
  }

  #social-media-p {
    font-size: 16px;
    padding: 8px 0 !important;
    margin: 0 0 0 0 !important;
  }

  #facebook-icon-btn,
  #linkedin-icon-btn {
    height: 22px;
    width: 22px;
  }

  #facebook-icon,
  #linkedin-icon {
    height: 22px;
    width: 22px;
  }

  #mission-statment {
    font-size: 16px;
    padding: 8px 0 !important;
  }

  #main-logo-icon {
    height: 120px !important;
    width: 125px !important;
  }
}

@media screen and (min-width: 1450px) and (max-width: 2150px) {
  .sidebar {
    width: 285px;
  }
}

@media screen and (min-width: 2151px) {
  .sidebar {
    width: 320px;
  }
  .menu-icon {
    height: 38px;
    width: 38px;
  }
  .sidebar-options-text span {
    font-size: 22px;
  }
  #logout-btn {
    width: 150px;
    font-size: 16px;
  }
}
