/* > Modal */
.new-user-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(79, 79, 79, 0.5);
}

/* div container in modal */
.new-user-modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 3px solid var(--outline-color);
  background-color: var(--main-blue);
  border-radius: 5px;
  width: 825px;
  position: relative;
  top: 50px;
}

.input-user-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 725px;
  padding: 12px;
}

#new-user-modal-hr {
  min-width: 100% !important;
  padding: 0 !important;
  margin: 15px !important;
  border-top: 2px solid var(--outline-color);
}

.new-user-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 750px;
}

#new-user-modal-info {
  color: var(--p-white);
  font-size: 17px;
  font-weight: var(--default-font-weight);
  font-family: var(--default-font);
  text-align: center;
  max-width: 750px !important;
}

#new-user-modal-header {
  text-align: center;
  max-width: 685px !important;
  font-size: 28px;
  color: var(--main-header-color);
  font-weight: var(--font-weight-bold);
  margin-bottom: 5px;
  text-shadow: var(--header-text-shadow);
  font-family: var(--default-font);
  pointer-events: none !important;
  user-select: none !important;
}

/* | Existing user checkbox container | */
.existing-user-container {
  display: flex;
  align-self: flex-start;
  justify-content: center;
  align-items: center;
  width: 215px;
  padding: 10px;
  margin: 10px 0px;
}

#existing-user-label {
  color: var(--label-color) !important;
  font-size: 20px !important;
  font-family: var(--default-font) !important;
  font-weight: var(--default-font-weight) !important;
  text-shadow: var(--form-label-text-shadow) !important;
}

.existing-user-checkbox {
  margin: 0;
  height: 50px !important;
  width: 50px !important;
}

.existing-user-checkbox > svg {
  height: 30px !important;
  width: 30px !important;
}

.existing-user-checkbox.Mui-checked {
  color: rgb(64, 242, 9) !important;
}

.new-user-form .input-user-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin: 10px;
  width: 725px;
}

.user-name-root-input,
.user-email-root-input,
.user-phone-root-input,
.user-title-root-input {
  color: var(--input-text-color) !important;
  font-family: var(--default-font);
  text-indent: 8px !important;
  padding: 0 !important;
  box-shadow: none !important;
  padding: 0;
  margin: 0;
  text-indent: 8px;
  font-weight: var(--default-font-weight) !important;
  border-radius: 5px !important;
  min-width: 290px;
  height: 100%;
}

.user-name-root,
.user-email-root,
.user-phone-root,
.user-title-root {
  display: flex;
  align-self: flex-start;
  align-items: center;
  justify-content: space-between;
  border: none;
  border-radius: 5px;
  outline: none;
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
  width: 100%;
  background-color: transparent;
}

.user-notchedOutline,
.user-phone-notchedOutline,
.user-email-notchedOutline,
.user-title-notchedOutline {
  border: none !important;
  outline: none !important;
}

.user-notchedOutline:focus-within,
.user-phone-notchedOutline:focus-within,
.user-email-notchedOutline:focus-within,
.user-title-notchedOutline:focus-within {
  outline: none !important;
  border: none !important;
}

#new-user-formControl {
  box-shadow: var(--input-box-shadow);
  width: 400px;
  height: 40px;
  border-radius: 5px;
  background-color: var(--input-white-bg);
}

#new-user-formControl.required {
  outline: 2px solid var(--input-outline-required) !important;
  box-shadow: var(--input-box-shadow);
}

.user-name-root-input#user-name-input,
.user-email-root-input#user-email-input,
.user-phone-root-input#user-phone-input,
.user-title-root-input#user-title-input {
  color: var(--input-text-color) !important;
  font-size: 18px;
  font-weight: var(--default-font-weight);
  text-indent: 8px;
  padding: 0 !important;
  margin: 0 !important;
  border-radius: 5px;
  box-shadow: none;
  z-index: 999;
  letter-spacing: 0.7px;
  height: inherit;
  font-family: var(--default-font);
  background-color: transparent;
}

#user-name-label,
#user-email-label,
#user-phone-label,
#user-title-label {
  color: var(--input-label-color) !important;
  font-family: var(--default-font);
  font-size: 16px !important;
  font-weight: var(--default-font-weight);
  text-shadow: none !important;
  transform: translate(8px, 9px) scale(1);
}

#user-name-label.user-label-shrink,
#user-phone-label.user-label-shrink,
#user-email-label.user-label-shrink,
#user-title-label.user-label-shrink {
  color: var(--shrink-label-color) !important;
  transform: translate(3px, -18px) scale(0.78) !important;
}

.title-placeholder-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

/* $ PLACEHOLDER $ */
#user-title-placeholder {
  font-family: var(--default-font);
  position: absolute;
  text-shadow: none;
  text-indent: 8px;
  font-weight: var(--default-font-weight);
  box-shadow: none;
  font-size: 18px;
  letter-spacing: 0.7px;
  padding: 0 !important;
  color: var(--input-place-holder-color) !important;
  border: none;
  border-radius: 5px;
  outline: none;
  pointer-events: none;
  min-width: 310px;
  background-color: transparent;
  min-height: 40px;
}

.dashboard-user-form-adornment#input-adornment {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px 0 0 !important;
  border-radius: 50%;
  width: 35px;
  height: 35px;
}

.dashboard-user-form-adornment#input-adornment #clear-icon-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  margin: 0 !important;
  pointer-events: auto;
}

.dashboard-user-form-adornment#input-adornment:hover {
  cursor: pointer;
  background-color: rgba(255, 88, 88, 0.276);
}

.clear-icon {
  font-size: 25px;
  color: var(--close-icon-color);
  stroke: var(--close-icon-color);
  margin: 0;
  padding: 0;
}

.user-button-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 45px;
  border: none;
  font-size: 16px;
  font-weight: var(--default-font-weight);
  margin: 25px 0px 15px 0px;
  width: 280px;
}

#new-user-submit,
#user-restore-btn {
  color: var(--btn-white-text);
  background-color: var(--btn-green);
  font-size: 16px;
  font-family: var(--default-font);
  font-weight: var(--default-font-weight);
  letter-spacing: 0.1px;
}

#user-logout-btn {
  color: var(--btn-white-text);
  background: var(--btn-red);
  font-size: 16px;
  font-family: var(--default-font) !important;
  font-weight: var(--default-font-weight) !important;
  letter-spacing: 0.1px;
}

#user-logout-btn:hover {
  cursor: pointer;
  background-color: var(--btn-red-hover);
}

#new-user-submit:hover,
#user-restore-btn:hover {
  cursor: pointer;
  background-color: var(--btn-green-hover);
}

#new-user-submit:disabled,
#user-restore-btn:disabled {
  background-color: var(--disabled-btn-bg);
  color: var(--disabled-btn-color);
  cursor: not-allowed !important;
  box-shadow: none;
  outline: none;
  border: none;
}
