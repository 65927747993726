#user-settings-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100vw;
  min-height: calc(100vh - 162px);
  position: relative;
  top: 55px;
  height: auto;
  transition: all 150ms ease;
  box-sizing: border-box;
}

#user-settings-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 1000px;
  min-height: 450px;
  transition: all 150ms ease;
  box-sizing: border-box;
  outline: 3px solid var(--outline-color);
  background-color: var(--main-blue);
  box-shadow: var(--input-container-box-shadow);
  margin-top: 25px;
  border-radius: 3px;
}

#user-info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 95%;
  height: auto;
  padding: 10px 0;
  margin: 10px 0;
  gap: 10px;
}

#user-settings-header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: max-content;
  padding: 10px 0;
  margin: 0;
  border-bottom: 3px solid var(--outline-color);
}

#user-settings-h1 {
  color: var(--main-header-color);
  font-size: 1.8cqw;
  font-weight: var(--font-weight-bold);
  text-shadow: var(--header-text-shadow);
  font-family: var(--default-font);
  pointer-events: none !important;
  user-select: none !important;
  padding: 0;
  margin: 0;
}

#user-settings-hr {
  width: 100%;
  margin: 0;
  padding: 0;
  border: none;
  border-bottom: 3px solid var(--outline-color);
}

.settings-label-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  height: auto;
  padding: 10px;
  margin: 10px 0;
}

.settings-label {
  font-size: 23px;
  font-weight: var(--default-font-weight) !important;
  font-family: var(--default-font) !important;
  color: var(--label-color) !important;
  text-shadow: var(--form-label-text-color) !important;
  margin-right: auto;
  pointer-events: none !important;
  user-select: none !important;
}

/* - FORM CONTROL */
.user-settings-form-root#settings-name-form,
.user-settings-form-root#settings-email-form,
.user-settings-form-root#settings-phone-form,
.user-settings-form-root#settings-title-form {
  box-shadow: var(--input-box-shadow);
  background-color: var(--input-white-bg);
  border-radius: 5px;
  border: none !important;
  outline: none !important;
  height: 40px;
  width: 425px;
  padding: 0 !important;
  margin: 0 !important;
}

.name-input#user-name-input,
.email-input#user-email-input,
.phone-input#user-phone-input,
.title-input#user-title-input {
  color: var(--input-text-color);
  font-size: 18px;
  box-shadow: none;
  text-indent: 8px;
  padding: 0 !important;
  margin: 0 !important;
  font-weight: var(--default-font-weight);
  border-radius: 5px;
  z-index: 999;
  letter-spacing: 0.7px;
  font-family: var(--default-font);
  height: inherit;
  background-color: transparent;
}

.name-input-root,
.email-input-root,
.phone-input-root,
.title-input-root {
  display: flex;
  align-self: center;
  justify-content: space-between;
  align-items: center;
  margin: 0 !important;
  padding: 0 !important;
  background-color: transparent;
  border: none;
  height: 100% !important;
  width: 100% !important;
}

.name-input-root,
.settings-notchedOutline {
  border: none !important;
  outline: none !important;
}

.name-input-root,
.settings-notchedOutline:focus-within {
  border: none !important;
  outline: none !important;
}

.email-input-root,
.settings-notchedOutline {
  border: none !important;
  outline: none !important;
}

.email-input-root,
.settings-notchedOutline:focus-within {
  border: none !important;
  outline: none !important;
}

.phone-input-root,
.settings-notchedOutline {
  border: none !important;
  outline: none !important;
}

.phone-input-root,
.settings-notchedOutline:focus-within {
  border: none !important;
  outline: none !important;
}

.title-input-root,
.settings-notchedOutline {
  border: none !important;
  outline: none !important;
}

.title-input-root,
.settings-notchedOutline:focus-within {
  border: none !important;
  outline: none !important;
}

/* - Input Label */
.user-settings-input-label#user-name-input-label,
.user-settings-input-label#user-email-input-label,
.user-settings-input-label#user-phone-input-label,
.user-settings-input-label#user-title-input-label {
  font-family: var(--default-font) !important;
  color: var(--input-label-color);
  font-weight: var(--default-font-weight) !important;
  transform: translate(14px, 9px) scale(1);
}

.user-settings-input-label.empty-value-label#user-name-input-label,
.user-settings-input-label.empty-value-label#user-email-input-label,
.user-settings-input-label.empty-value-label#user-phone-input-label,
.user-settings-input-label.empty-value-label#user-title-input-label {
  font-family: var(--default-font);
  color: rgba(177, 83, 83, 0.713);
  font-weight: var(--default-font-weight);
  transform: translate(10px, 8px) scale(1.1);
}

.user-settings-input-label.user-label-shrink {
  color: var(--shrink-label-color) !important;
  transform: translate(3px, -18px) scale(0.75) !important;
}

/* | CLEAR BUTTON | */
.text-field-clear-adornment#text-adornment {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px 0 0 !important;
  border-radius: 50%;
  width: 35px;
  height: 35px;
}

#clear-icon-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  margin: 0;
  padding: 10px;
  pointer-events: auto;
}

.text-field-clear-adornment#text-adornment:hover {
  cursor: pointer;
  background-color: rgba(255, 88, 88, 0.276);
}

.close-icon {
  font-size: 25px;
  fill: var(--close-icon-color);
  color: var(--close-icon-color);
  stroke: var(--close-icon-color);
  margin: 0;
  padding: 0;
}

/* - Save & Update Btn */
#settings-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 350px;
  gap: 10px;
  height: auto;
  padding: 10px;
  margin: 15px 0 20px 0;
}

.settings-btn#update-settings-btn {
  background-color: var(--argus-blue-light);
  color: var(--argus-white);
  font-weight: var(--font-weight-bold);
  font-family: var(--default-font) !important;
  font-size: 17px;
  padding: 0 !important;
  margin: 0 !important;
  line-height: 1;
  height: 35px;
  width: 90px;
}

.settings-btn#update-settings-btn:hover {
  background-color: var(--argus-blue-dark);
  cursor: pointer;
}

.settings-btn#save-changes-btn {
  background-color: var(--btn-green);
  color: var(--btn-white-text);
  font-size: 15px;
  font-weight: var(--font-weight-bold);
  font-family: var(--default-font);
  cursor: pointer;
  transition: all 150ms ease-in-out;
  padding: 0;
  height: 35px;
  width: 145px;
}

.settings-btn#save-changes-btn:hover {
  background-color: var(--btn-green-hover);
  color: var(--btn-white-text);
  font-size: 15px;
  font-weight: var(--font-weight-bold);
  font-family: var(--default-font);
  cursor: pointer;
}

.settings-btn#cancel-changes-btn {
  background-color: var(--btn-red);
  color: var(--btn-white-text);
  font-size: 15px;
  font-weight: var(--font-weight-bold);
  font-family: var(--default-font);
  cursor: pointer;
  transition: all 350ms ease-in-out;
  padding: 0;
  height: 35px;
  width: 90px;
}

.settings-btn#cancel-changes-btn:hover {
  background-color: var(--btn-red-hover);
  color: var(--btn-white-text);
  font-size: 15px;
  font-weight: var(--font-weight-bold);
  font-family: var(--default-font);
  cursor: pointer;
  transition: all 350ms ease-in-out;
}
