.project-files-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100vw;
  height: auto;
  min-height: calc(100vh - 162px);
  transition: all 0.3s cubic-bezier(0.25, 1, 0.5, 1);
  box-sizing: border-box;
  position: relative;
  top: 55px;
}

.client-file-structure {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100vw;
  min-height: calc(100vh - 170px);
  height: auto;
  transition: all 0.3s cubic-bezier(0.25, 1, 0.5, 1);
  box-sizing: border-box;
}

/* % BREADCRUMBS */
.breadcrumbs-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  margin: 10px 0 5px 0;
}

.breadcrumbs-root .breadcrumbs-value {
  font-family: var(--default-font) !important;
  font-size: 22px !important;
}

.breadcrumbs-root .breadcrumbs-separator {
  font-family: var(--default-font) !important;
  font-size: 22px !important;
  font-weight: var(--default-font-weight) !important;
  color: #2a2a2a;
}

.breadcrumbs-root .breadcrumbs-btn.root-link-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px !important;
  box-sizing: border-box;
  background-color: transparent;
}

.breadcrumbs-root .breadcrumbs-value.root-text {
  font-weight: var(--default-font-weight) !important;
  font-family: var(--default-font) !important;
  justify-content: center;
  color: #2483f7 !important;
  letter-spacing: 0px;
  padding: 0 !important;
  line-height: 1.5;
  text-transform: capitalize !important;
}

.breadcrumbs-btn:hover .breadcrumbs-value.root-text {
  cursor: pointer;
  color: #1067be !important;
  text-decoration-color: #1f6cb7 !important;
  background-color: transparent;
}

.breadcrumbs-btn.root-link-btn:hover #root-folder-icon {
  color: #0d67c2;
  text-decoration-color: #0d67c2;
}

.breadcrumbs-root .breadcrumbs-value.current-text {
  font-weight: var(--default-font-weight);
  color: rgb(65, 65, 65);
  user-select: none;
  cursor: default;
}

/* % BADGE % */
.folder-badge {
  background-color: rgba(58, 58, 58, 0.894) !important;
  font-family: var(--default-font) !important;
  font-weight: 700 !important;
  color: rgb(224, 224, 224);
  font-size: 16px !important;
  height: 30px !important;
  width: 30px !important;
  border-radius: 50% !important;
  z-index: 999;
  user-select: none;
}

.clients-folder-badge {
  background-color: rgba(58, 58, 58, 0.894) !important;
  font-family: var(--default-font) !important;
  font-weight: 700 !important;
  color: rgb(224, 224, 224);
  font-size: 16px !important;
  height: 30px !important;
  width: 30px !important;
  border-radius: 50% !important;
  z-index: 999;
  user-select: none;
}

/* | FOLDERS | */
#root-folder-icon {
  color: #2483f7;
  stroke: none;
  height: 24px;
  width: 24px;
  margin: 0 !important;
  padding: 0 !important;
}

.folders-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: fit-content;
  height: auto;
  max-height: 100%;
  transition: all 0.3s cubic-bezier(0.25, 1, 0.5, 1);
  box-sizing: border-box;
  gap: 20px 35px;
  flex-wrap: wrap;
  padding: 0 15px;
}

.folders-container#admin-clients-folder-container {
  margin-top: 10px;
}

.folder-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 210px;
  width: 200px;
  border-radius: 8px;
}

.folder-container:hover {
  cursor: pointer;
  background-color: #91bfd7bc;
}

.folder-container.disabled-folder:hover {
  cursor: not-allowed;
  background-color: transparent;
}

.folder-container.disabled-folder:hover .folder-badge {
  color: #d70606f1;
  font-weight: var(--default-font-weight);
}

.folder {
  height: 130px !important;
  width: 155px !important;
  filter: drop-shadow(0px 3px 1px rgba(0, 0, 0, 0.35));
  margin: 0 !important;
  padding: 0 !important;
  fill: rgba(255, 225, 107, 0.894) !important;
}

.folder.default-folder {
  fill: rgba(255, 225, 107, 0.894);
}

.folder.uploaded-folder {
  fill: #6ec649 !important;
}

.folder.admin-folder {
  fill: rgb(216, 224, 231);
}

.folder.missing-folder {
  fill: #cb4e4e !important;
}

.project-folder-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 169px;
  margin: 0 !important;
  padding: 0 !important;
}

.project-folder-status {
  position: absolute;
  font-family: var(--default-font);
  font-weight: var(--default-font-weight);
  font-size: 19px;
  margin: 14px 0 0 0 !important;
  text-align: center;
  padding: 0 !important;
}

.folder-name {
  color: var(--folder-name);
  font-family: var(--default-font);
  font-weight: var(--default-font-weight);
  font-size: 17px;
  margin: 0 !important;
  text-align: center;
  width: 100%;
  pointer-events: none;
  user-select: none;
  word-wrap: break-word;
}

/* % ADD NEW FILE BUTTON */
.upload-new-file-btn#upload-new-file-btn {
  background-color: var(--btn-green);
  color: var(--btn-white-text);
  margin-left: 25px;
  height: 50px;
  width: 50px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2), 1px 1px 3px rgba(0, 0, 0, 0.1),
    -1px 1px 3px rgba(0, 0, 0, 0.1);
  transition: all 350ms ease;
}

.upload-new-file-btn#upload-new-file-btn:hover {
  background-color: var(--btn-green-hover);
}

#upload-new-file-icon {
  fill: rgb(232, 232, 232);
  stroke: rgb(232, 232, 232);
  stroke-width: 0.5px;
  height: 35px;
  width: 35px;
  filter: drop-shadow(0px 1px 2px rgba(22, 22, 22, 0.2));
  transition: all 350ms ease;
}

.admin-folder-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  width: auto;
}

.admin-folder-icon {
  position: absolute;
  height: 80px !important;
  width: 85px !important;
  margin: 15px 0 0 0 !important;
  padding: 0 !important;
}
