.upload-files-modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-missing-files {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--main-blue);
  height: 450px;
  width: 600px;
  padding: 15px;
  border-radius: 10px;
}

.drag-files-box#drag-files-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  outline: 4px dashed var(--outline-color);
  text-align: center;
  cursor: pointer;
  gap: 40px;
  margin: 0;
  padding: 2px 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.upload-missing-icon#upload-missing-icon {
  font-size: 90px;
  margin: 0;
  padding: 0;
  color: var(--argus-white);
}

.drag-drop-text#drag-drop-text {
  font-size: 26px;
  font-family: var(--default-font);
  font-weight: var(--font-weight-bold);
  color: var(--argus-white);
}
