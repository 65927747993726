#create-file-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 160px;
  min-height: calc(100vh - 160px);
  max-height: calc(100vh - 160px);
  background-color: rgba(75, 75, 75, 0.412);
}

#create-file-form {
  display: flex;
  flex-direction: column;
  align-self: center !important;
  align-items: space-between;
  width: 850px;
  min-height: 400px !important;
  max-height: fit-content !important;
  outline: 4px solid var(--outline-color);
  background-color: var(--main-blue);
  border-radius: 2px;
}

#create-file-heading {
  color: rgba(243, 243, 255, 0.989);
  font-size: 32px;
  font-weight: 900;
  margin-bottom: 5px;
  text-shadow: 2px 3px 3px rgba(55, 55, 55, 0.3);
  font-family: var(--default-font);
  pointer-events: none !important;
  user-select: none !important;
  text-align: center;
}

#create-file-hr {
  width: 100%;
  margin: 15px 0 15px 0;
  padding: 0;
  border-bottom: 3px solid var(--outline-color);
}

#create-file-p {
  font-size: 18px;
  font-weight: var(--default-font-weight);
  text-shadow: none;
  font-family: var(--default-font);
  line-height: 1.2;
  pointer-events: none;
  user-select: none;
  color: var(--p-yellow) !important;
  width: fit-content;
  max-width: 835px;
  text-shadow: none !important;
  text-align: center;
  align-self: center;
}

/* | FILE SELECT | */
#missing-files-form {
  display: flex;
  align-items: center;
  justify-content: center !important;
  align-self: center !important;
  width: 450px;
  height: 45px;
  margin: 15px 0;
  background-color: var(--input-white-bg);
  color: var(--input-text-color) !important;
  border-radius: 5px;
  box-shadow: var(--input-box-shadow);
  border: none;
  outline: none;
}

#missing-files-select::before {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.files-select-root::before,
.files-select-root::after {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.files-select-root {
  height: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  border-radius: 5px !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  color: var(--input-text-color) !important;
}

.files-select-root:focus,
.files-select-root:focus-within,
.files-select-root:hover {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

#missing-files-select {
  display: flex !important;
  justify-self: center !important;
  align-self: center !important;
  align-items: center !important;
  text-indent: 8px !important;
  margin: 0 !important;
  padding: 0 !important;
  height: 45px !important;
  min-width: 45px !important;
  box-shadow: none !important;
  border: none !important;
  outline: none !important;
  font-size: 18px !important;
  font-weight: var(--default-font-weight) !important;
  font-family: var(--default-font) !important;
  color: var(--input-text-color) !important;
}

#missing-files-select:focus,
#missing-files-select:focus-within,
#missing-files-select:hover {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.files-select-native-input,
.files-select-native-input:focus,
.files-select-native-input:focus-within {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

#select-missing-files-label {
  font-family: var(--default-font) !important;
  color: var(--input-label-color) !important;
  font-weight: var(--default-font-weight) !important;
  font-size: 18px !important;
  transform: translate(14px, 10px) scale(1);
}

#select-missing-files-label.missing-files-label-shrink {
  color: var(--shrink-label-color) !important;
  transform: translate(3px, -21px) scale(0.75) !important;
}

.files-select-icon {
  color: rgb(125, 125, 125) !important;
  border-radius: 50% !important;
  margin-right: 5px !important;
}

.select-paper {
  display: flex;
  align-items: flex-start !important;
  padding: 0 !important;
  margin: 0 !important;
  justify-content: center;
  background-color: var(--main-blue) !important;
}

.select-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start !important;
  align-self: flex-start !important;
  text-align: center;
  margin: 0;
  padding: 0 !important;
  width: 100%;
  max-height: 350px;
  overflow-y: scroll;
  background-color: transparent;
  border: 2px solid var(--outline-color);
}

.files-select-item-divider {
  border-bottom: 2px solid var(--outline-color) !important;
}

.files-select-item.files-select-item-root {
  width: 100%;
  color: var(--label-color) !important;
  font-family: var(--default-font) !important;
  font-size: 18px !important;
  font-weight: var(--default-font-weight) !important;
  text-align: center !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.files-select-item.files-select-item-root:hover {
  cursor: pointer;
  background-color: var(--argus-blue-dark);
}

#clear-files-item.files-select-item-root {
  font-family: var(--default-font) !important;
  font-size: 18px !important;
  font-weight: var(--default-font-weight) !important;
  color: rgb(240, 240, 240) !important;
  background-color: rgb(210, 31, 31);
  text-align: center !important;
}

#clear-files-item.files-select-item-root:hover {
  cursor: pointer;
  background-color: rgb(184, 44, 44);
}

#other-files-item.files-select-item-root {
  font-family: var(--default-font) !important;
  font-size: 18px !important;
  font-weight: var(--default-font-weight) !important;
  background-color: rgb(63, 182, 33);
  color: rgb(240, 240, 240);
  text-align: center !important;
}

#other-files-item.files-select-item-root:hover {
  cursor: pointer;
  background-color: rgb(49, 155, 23);
}

/* | NAME INPUT | */
#file-name-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 820px;
  margin: 15px 0 25px 0;
}

#file-name-info-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  font-family: var(--default-font);
  margin: 0;
  padding: 5px 0;
  width: 510px;
}

.new-file-form-label {
  font-size: 22px;
  font-weight: var(--default-font-weight) !important;
  font-family: var(--default-font) !important;
  color: var(--label-color) !important;
  text-shadow: var(--form-label-text-color) !important;
  margin-right: auto;
  pointer-events: none !important;
  user-select: none !important;
}

#file-name-form {
  box-shadow: var(--input-box-shadow);
  background-color: var(--input-white-bg);
  border-radius: 5px;
  border: none;
  outline: none;
  width: 450px;
  height: 40px;
  margin: 0;
  padding: 0;
}

#file-name-form.required {
  outline: 2px solid var(--input-outline-required) !important;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 1px 1px, rgba(3, 8, 15, 0.09) 0px 1px 1px,
    rgba(0, 0, 0, 0.217) 0px 2px 3px, rgba(2, 1, 15, 0.09) 0px 3px 4px,
    rgba(0, 0, 0, 0.09) 0px 4px 4px;
}

.file-name-input-root {
  display: flex;
  align-self: flex-start;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  border: none;
  outline: none;
  padding: 0 !important;
  margin: 0 !important;
  height: 100% !important;
  width: 100% !important;
}

.file-name-input-notched-outline {
  border: none !important;
  outline: none !important;
}

.file-name-input-notched-outline:focus-within {
  border: none !important;
  outline: none !important;
}

.file-name-input-input#file-name-input {
  color: rgb(20, 20, 20);
  font-size: 18px;
  box-shadow: none;
  text-indent: 8px;
  padding: 0 !important;
  margin: 0 !important;
  font-weight: var(--default-font-weight);
  border-radius: 5px;
  z-index: 999;
  letter-spacing: 0.7px;
  font-family: var(--default-font);
  height: inherit;
  background-color: transparent;
}

.file-info-circ {
  height: 30px;
  width: 30px;
  color: rgb(242, 242, 242);
}

#file-name-label {
  font-family: var(--default-font) !important;
  color: rgb(125, 125, 125) !important;
  font-weight: var(--default-font-weight) !important;
  transform: translate(14px, 9px) scale(1);
}

#file-name-label.file-name-label-shrink {
  color: rgb(240, 240, 240) !important;
  transform: translate(3px, -21px) scale(0.75) !important;
}

.file-name-clear-adornment#file-name-adornment {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px 0 0 !important;
  border-radius: 50%;
  width: 35px;
  height: 35px;
}

#file-name-clear-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  margin: 0;
  padding: 10px;
  pointer-events: auto;
}

.file-name-clear-adornment#file-name-adornment:hover {
  cursor: pointer;
  background-color: rgba(255, 88, 88, 0.276);
}

.clear-icon#clear-file-icon {
  font-size: 25px;
  fill: rgb(253, 0, 0);
  color: rgb(255, 0, 0);
  stroke: rgb(255, 0, 0);
  margin: 0;
  padding: 0;
}

#new-file-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 820px;
  margin: 25px 0 auto 0;
}

.new-file-btn#new-file-btn {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: var(--btn-light-blue);
  color: var(--btn-white-text);
  border: none;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 3px, rgba(3, 8, 15, 0.09) 0px 1px 2px,
    rgba(0, 0, 0, 0.11) 0px 3px 4px;
  font-size: 17px;
  font-family: var(--default-font) !important;
  font-weight: 700 !important;
  width: 210px;
  height: 40px;
  margin: 0 25px 0 0;
}

.new-file-btn#new-file-btn:hover {
  cursor: pointer;
  background-color: var(--btn-light-blue-hover);
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(3, 8, 15, 0.09) 0px 1px 2px,
    rgba(0, 0, 0, 0.11) 0px 4px 5px, rgba(2, 1, 15, 0.09) 0px 2px 3px,
    rgba(0, 0, 0, 0.09) 0px 4px 4px;
}

.new-file-btn#new-file-btn:disabled {
  background-color: var(--disabled-btn-bg);
  color: var(--disabled-btn-color);
  cursor: not-allowed !important;
  box-shadow: none;
  pointer-events: auto;
}

.new-file-btn#new-file-btn:disabled:hover {
  background-color: var(--disabled-btn-bg);
  color: var(--disabled-btn-color);
  cursor: not-allowed !important;
  box-shadow: none;
}

.new-file-btn#new-file-btn:disabled #new-file-icon {
  fill: var(--disabled-btn-color) !important;
  stroke: var(--disabled-btn-color) !important;
}

.new-file-btn#existing-file-btn {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: var(--btn-green);
  color: var(--btn-white-text);
  border: none;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 3px, rgba(3, 8, 15, 0.09) 0px 1px 2px,
    rgba(0, 0, 0, 0.11) 0px 3px 4px;
  font-size: 17px;
  font-family: var(--default-font) !important;
  font-weight: 700 !important;
  width: 210px;
  height: 40px;
  margin: 0 25px 0 0;
}

.new-file-btn#existing-file-btn:hover {
  cursor: pointer;
  background-color: var(--btn-green-hover);
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(3, 8, 15, 0.09) 0px 1px 2px,
    rgba(0, 0, 0, 0.11) 0px 4px 5px, rgba(2, 1, 15, 0.09) 0px 2px 3px,
    rgba(0, 0, 0, 0.09) 0px 4px 4px;
}

.new-file-btn#existing-file-btn:disabled {
  background-color: var(--disabled-btn-bg);
  color: var(--disabled-btn-color);
  cursor: not-allowed !important;
  box-shadow: none;
  pointer-events: auto;
}

.new-file-btn#existing-file-btn:disabled:hover {
  background-color: var(--disabled-btn-bg);
  color: var(--disabled-btn-color);
  cursor: not-allowed !important;
  box-shadow: none;
}

.new-file-btn#existing-file-btn:disabled #new-file-icon {
  fill: var(--disabled-btn-color) !important;
  stroke: var(--disabled-btn-color) !important;
}

#new-file-icon {
  fill: rgb(240, 240, 240) !important;
  stroke: rgb(240, 240, 240) !important;
  stroke-width: 0.7px;
  height: 22px;
  width: 22px;
}

#new-files-btn-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 300px;
  margin: 0 0 15px 0;
  padding: 0;
  gap: 25px;
}

#selected-file-name {
  font-size: 18px;
  font-weight: var(--default-font-weight);
  font-family: var(--default-font);
  color: rgb(240, 240, 240);
  margin: 0;
  padding: 0;
  min-width: 275px;
  text-align: center;
}

#drag-files-box-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  background-color: var(--argus-blue-darker);
  border-radius: 5px;
  padding: 10px;
  height: 150px;
  min-width: 685px;
  max-width: 90%;
  margin: 20px 0 auto 0;
  cursor: pointer;
}

#drag-files-box-container.hide-container {
  display: none;
}

#drag-files-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  outline: 3px dashed var(--outline-color);
  gap: 20px;
  height: 100%;
  width: 100%;
}

.drag-files-text#drag-files-text {
  font-size: 16px;
  font-family: var(--default-font);
  font-weight: var(--font-weight-bold);
  color: var(--argus-white);
}

.upload-new-icon#upload-new-icon {
  font-size: 80px;
  color: var(--argus-white);
}

.cancel-new-file-btn#cancel-new-file-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(222, 13, 13);
  color: rgb(240, 240, 240);
  border: none;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 3px, rgba(3, 8, 15, 0.09) 0px 1px 2px,
    rgba(0, 0, 0, 0.11) 0px 3px 4px;
  font-size: 17px;
  font-family: var(--default-font);
  font-weight: 700 !important;
  width: 110px;
  height: 35px;
  margin: 15px;
}

.cancel-new-file-btn#cancel-new-file-btn:hover {
  background-color: rgb(179, 5, 5);
  cursor: pointer;
}

.submit-new-file-btn#submit-new-file-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--btn-green);
  color: var(--btn-white-text);
  border: none;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 3px, rgba(3, 8, 15, 0.09) 0px 1px 2px,
    rgba(0, 0, 0, 0.11) 0px 3px 4px;
  font-size: 17px;
  font-family: var(--default-font);
  font-weight: 700 !important;
  width: 120px;
  height: 40px;
}

.submit-new-file-btn#submit-new-file-btn:hover {
  background-color: var(--btn-green-hover);
  cursor: pointer;
}

.submit-new-file-btn#submit-new-file-btn:disabled {
  background-color: var(--disabled-btn-bg);
  color: var(--disabled-btn-color);
  cursor: not-allowed !important;
  box-shadow: none;
  pointer-events: auto;
}

.submit-new-file-btn#submit-new-file-btn:disabled:hover {
  background-color: var(--disabled-btn-bg);
  color: var(--disabled-btn-color);
  cursor: not-allowed !important;
  box-shadow: none;
}
