/* | FILES | */
#missing-files-description {
  color: #db1919;
  font-family: var(--default-font);
  font-weight: var(--default-font-weight);
  font-size: 1.2rem;
  margin: 10px 0;
  padding: 0;
  text-align: center;
  width: 92%;
}

#require-files-button.require-files-button {
  display: flex;
  flex-direction: row;
  font-family: var(--default-font);
  font-weight: var(--default-font-weight);
  font-size: 14px;
  line-height: 1;
  height: 35px;
  width: 142px;
  background-color: var(--btn-red);
  color: var(--btn-white-text);
  position: absolute;
  right: 3rem;
  top: 20px;
}

#require-files-button.require-files-button.close {
  display: flex;
  flex-direction: row;
  font-family: var(--default-font);
  font-weight: var(--default-font-weight);
  font-size: 14px;
  line-height: 1;
  height: 35px;
  width: 75px;
  background-color: var(--btn-red);
  color: var(--btn-white-text);
  position: absolute;
  right: 3rem;
  top: 20px;
}

#submit-required-files-button.submit-required-files-button {
  display: flex;
  flex-direction: row;
  font-family: var(--default-font);
  font-weight: var(--default-font-weight);
  font-size: 14px;
  line-height: 1;
  height: 35px;
  width: 75px;
  background-color: var(--btn-green);
  color: var(--btn-white-text);
  position: absolute;
  right: 3rem;
  top: 65px;
}

.file-type-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  min-height: fit-content !important;
  gap: 15px;
  z-index: 990 !important;
}

/* % File Settings Container  */
.file-settings-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: fit-content;
  margin: 5px 0 0 0;
  padding: 5px;
  cursor: pointer;
}

/* ^ BUTTON GROUP */
.uploaded-file-button-group.uploaded-file-group-root {
  box-shadow: 0 1px 2px 1px rgba(28, 28, 28, 0.377) !important;
  height: 35px !important;
}

/* ^ BUTTONS */
.file-settings-button {
  font-size: 14px !important;
  font-weight: var(--default-font-weight) !important;
  font-family: var(--default-font) !important;
  box-shadow: none !important;
  outline: none !important;
  padding: 5px 8px !important;
}

.file-settings-button.view-file-button#view-file-button {
  background-color: rgb(22, 150, 214);
  color: rgb(218, 218, 218);
  border: none;
  border-right: 1px solid rgba(0, 0, 0, 0.266);
  font-size: 14px;
  font-weight: var(--default-font-weight);
  font-family: var(--default-font);
  box-shadow: none;
  outline: none;
  margin: 0;
}

.file-settings-button.view-file-button#view-file-button:hover {
  background-color: rgb(5, 138, 191);
}

.file-settings-button.replace-file-button#replace-file-button {
  background-color: rgb(223, 124, 17);
  color: rgb(218, 218, 218);
  border: none;
  border-right: 1px solid rgba(0, 0, 0, 0.266);
  font-size: 14px;
  font-weight: var(--default-font-weight);
  font-family: var(--default-font);
  box-shadow: none;
  outline: none;
}

.file-settings-button.replace-file-button#replace-file-button:hover {
  background-color: rgb(211, 112, 7);
}

.upload-file-group {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  max-width: 90px;
  min-width: 90px;
  min-height: 100%;
  padding: 0;
  margin: 0;
  pointer-events: none !important;
}

.update-file-button#update-file-button {
  background-color: rgb(19, 128, 252);
  color: rgb(218, 218, 218);
  font-size: 14px;
  width: 90px;
  height: 35px;
  font-weight: var(--default-font-weight) !important;
  font-family: var(--default-font) !important;
  pointer-events: all !important;
}

.update-file-button#update-file-button:hover {
  background-color: rgb(14, 112, 223);
}

.file-settings-button.delete-file-button#delete-file-button {
  background-color: rgb(218, 27, 27);
  color: rgb(218, 218, 218);
  border: none;
  font-size: 14px !important;
  font-weight: var(--default-font-weight) !important;
  font-family: var(--default-font) !important;
  box-shadow: none;
  outline: none;
}

.file-settings-button.delete-file-button#delete-file-button:hover {
  background-color: rgb(198, 11, 11);
}

.file-settings-button.require-file-button#require-file-button {
  background-color: rgb(218, 27, 27);
  color: rgb(218, 218, 218);
  border: none;
  font-size: 14px !important;
  font-weight: var(--default-font-weight) !important;
  font-family: var(--default-font) !important;
  box-shadow: none;
  outline: none;
}

.file-settings-button.require-file-button#require-file-button:hover {
  background-color: rgb(198, 11, 11);
}

.file-settings-button.view-file-button#view-file-button:disabled,
.file-settings-button.replace-file-button#replace-file-button:disabled,
.file-settings-button.delete-file-button#delete-file-button:disabled {
  background-color: #9898988a !important;
  color: #e2e2e2d3 !important;
}

.file-settings-button.view-file-button#view-file-button:disabled:hover,
.file-settings-button.replace-file-button#replace-file-button:disabled:hover,
.file-settings-button.delete-file-button#delete-file-button:disabled:hover {
  cursor: not-allowed;
}

.file-settings-button.view-file-button#view-file-button:disabled,
.file-settings-button.replace-file-button#replace-file-button:disabled {
  border-right: 1px solid rgba(87, 87, 87, 0.204);
}

/* % File Icon Container */
.files-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 98%;
  max-width: 98%;
  min-height: fit-content;
  margin: 0;
  flex-wrap: wrap;
  gap: 15px;
  padding: 5px 0 5px 10px;
  pointer-events: none !important;
}

/* % FILE CONTAINER */
.file-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  height: 145px;
  width: 135px;
  border-radius: 8px;
  z-index: 1000 !important;
  pointer-events: all !important;
  position: relative;
}

.file-icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 130px;
  width: 100%;
  margin: 0;
  padding: 0;
  pointer-events: none !important;
}

.file-container.uploaded {
  height: 145px;
  width: 135px;
}

.file-container.missing {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  height: 140px;
  width: 130px;
}

.file-container.active-file,
.file-container.require-file {
  background-color: #91bfd7bc;
}

.file-container:hover {
  cursor: pointer;
  background-color: #91bfd7bc;
}

.require-file-checkbox-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  margin: 0 !important;
  align-self: flex-start;
  top: 2px;
  left: 2px;
  padding: 0;
  height: fit-content;
  width: fit-content;
}

.require-file-checkbox {
  margin: 0 !important;
  padding: 0 !important;
  z-index: 9999 !important;
  color: var(--btn-red) !important;
}

.file-icon {
  min-height: 80px !important;
  max-height: 80px !important;
  min-width: 60px !important;
  max-width: 60px !important;
  filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.35));
  margin: 10px 0 !important;
  padding: 0 !important;
  color: #efefeff6;
  pointer-events: none !important;
}

.file-category {
  color: var(--argus-black);
  font-family: var(--default-font);
  font-weight: var(--font-weight-bold);
  text-transform: uppercase;
  text-decoration: underline;
  letter-spacing: 0;
  font-size: 10px;
  width: 100%;
  text-overflow: ellipsis !important;
  overflow: hidden;
  white-space: nowrap;
  word-wrap: normal;
  text-align: center;
  pointer-events: none !important;
  user-select: none;
  margin: 0 0 0 0;
  padding: 0;
}

.file-name {
  color: var(--argus-black);
  font-family: var(--default-font);
  font-weight: var(--default-font-weight);
  letter-spacing: 0px;
  font-size: 10px;
  width: 100%;
  text-overflow: ellipsis !important;
  overflow: hidden;
  white-space: nowrap;
  word-wrap: normal;
  text-align: center;
  pointer-events: none !important;
  user-select: none;
  margin: 0 0 0 0;
  padding: 0 0 5px 0;
}

.file-name.missing-text {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  min-height: 25px;
  font-size: 10px;
  padding: 0 1px 0 1px;
  white-space: wrap;
  text-overflow: clip;
}

.file-name.missing-text.required-file-text {
  color: #cb0505;
  font-family: var(--default-font);
  font-weight: var(--font-weight-bold);
}

#required-extension-text,
#missing-extension-text {
  font-size: 0.57rem !important;
  font-weight: 700 !important;
  font-family: var(--default-font) !important;
  user-select: none !important;
  fill: rgb(240, 240, 240) !important;
  text-shadow: none !important;
  letter-spacing: -0.2px !important;
  margin: 0 !important;
  padding: 0 !important;
}

.required-file-svg-root.required-file-svg#required-file-svg {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-height: 18px !important;
  margin: 0 !important;
  scale: 0.5;
}

.required-file-svg-root.required-file-svg#required-file-svg.required-file-svg-fontSizeSmall {
  min-height: 18px !important;
  height: 18px !important;
  max-height: 18px !important;
  min-width: 15px !important;
  width: 15px !important;
  max-width: 15px !important;
  font-size: 0.25rem !important;
}

.required-file-icon-root.required-file-icon#required-file-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-height: 12px !important;
  font-size: 0.25rem !important;
  height: 12px !important;
  width: 15px !important;
}

.required-file-icon-root.required-file-icon#required-file-icon.required-file-icon-fontSizeSmall {
  min-height: 12px !important;
  height: 12px !important;
  max-height: 12px !important;
  min-width: 15px !important;
  width: 15px !important;
  max-width: 15px !important;
  stroke-width: 0% !important;
  font-size: 0.25rem !important;
}

/* UNKNOWN */
.unknown-file-svg-root.unknown-file-svg#unknown-file-svg {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-height: 12px !important;
  font-size: 0.25rem !important;
  height: 12px !important;
  width: 15px !important;
}

.unknown-file-svg-root.unknown-file-svg#unknown-file-svg.unknown-file-svg-fontSizeSmall {
  min-height: 12px !important;
  height: 12px !important;
  max-height: 12px !important;
  min-width: 15px !important;
  width: 15px !important;
  max-width: 15px !important;
  stroke-width: 0% !important;
  font-size: 0.25rem !important;
}

.unknown-file-icon-root.unknown-file-icon#unknown-file-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-height: 12px !important;
  font-size: 0.25rem !important;
  height: 12px !important;
  width: 15px !important;
}

.unknown-file-icon-root.unknown-file-icon#unknown-file-icon.unknown-file-icon-fontSizeSmall {
  min-height: 12px !important;
  height: 12px !important;
  max-height: 12px !important;
  min-width: 15px !important;
  width: 15px !important;
  max-width: 15px !important;
  stroke-width: 0% !important;
  font-size: 0.25rem !important;
}

#admin-upload-file-btn,
#upload-file-icon-btn {
  background-color: var(--btn-green) !important;
  pointer-events: all !important;
  color: var(--btn-white-text);
  margin: 15px;
  height: 55px;
  width: 55px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2), 1px 1px 3px rgba(0, 0, 0, 0.1),
    -1px 1px 3px rgba(0, 0, 0, 0.1);
  transition: all 350ms ease;
}

#admin-upload-file-btn:hover,
#upload-file-icon-btn:hover {
  background-color: var(--btn-green-hover) !important;
}

.new-file-icon#admin-add-icon,
.new-file-icon#add-icon {
  height: 30px;
  width: 30px;
  stroke: var(--btn-white-text);
}

.missing-extension-text#missing-extension-text {
  font-family: var(--default-font) !important;
  font-weight: var(--default-font-weight) !important;
  font-size: 9px !important;
  letter-spacing: 0 !important;
  user-select: none !important;
  pointer-events: none !important;
}

.required-extension-text#required-extension-text {
  font-family: var(--default-font) !important;
  font-weight: var(--default-font-weight) !important;
  font-size: 9px !important;
  letter-spacing: 0 !important;
  user-select: none !important;
  text-transform: lowercase !important;
  pointer-events: none !important;
}
